import React from 'react';
import styled from 'styled-components';
import {Button, Table, Modal, Tag} from 'antd';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import {formatDateStr} from '../../Utils/TimeUtil';
import * as OrderCustomUtil from '../../Utils/OrderCustomUtil';
import {
  FormSpec,
  transformQuery,
  exportExcelFile,
  getProductName,
  getPaymentStatusName,
  renderQueryInformation,
} from '../../Utils/OrderExportUtil';
import AdminSelectProductWidget from '../../Generators/AdminResource/AdminSelectProductWidget';
import AdminSelectUserCustomWidget from '../../Generators/AdminResource/AdminSelectUserCustomWidget';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';

const qs = require('query-string');
const pageSize = 50;

const defaultStartDate = new Date(
  new Date().getTime() - 365 * 24 * 60 * 60 * 1000,
)
  .toISOString()
  .split('T')[0];
const defaultEndDate = new Date(
  new Date(formatDateStr()).getTime() + 24 * 60 * 60 * 1000 - 1000,
)
  .toISOString()
  .split('T')[0];

const Columns = (queryParams) => [
  {
    title: '查詢結果',
    key: '#',
    render: (_, r) => {
      const route = r.order_id ? 'order' : 'checkout';
      const url = `/admin/${route}?action=detail&id=${r.id}`;

      return (
        <ColumnWrapper>
          {queryParams.qType === '入會費' && (
            <div className="container">
              <div className="kickoff">
                <Tag color="cyan">{queryParams.qType}</Tag>
                {OrderCustomUtil.isCancelStatus(r.status) && (
                  <Tag color="orange">
                    {OrderCustomUtil.getStatusLabel(r.status, r.payment_status)}
                  </Tag>
                )}
                <span className="created">
                  {new Date(r.created).toLocaleString('sv')}
                </span>
                <span className="number">
                  {r.order_id || `${r.order_number}*`}
                </span>
              </div>
              <div className="content">
                <span className="buyer">
                  {r.buyer_name} {r.buyer_phone} {r.buyer_email}
                </span>
                <div>
                  <span className="item">{r.item_name}</span>
                  {r.discount_name && (
                    <span className="discount">
                      [金額減項] {r.discount_name}
                    </span>
                  )}
                  <span className="amount">${r.amount}</span>
                  <span className={`${r.payment_status}`}>
                    {getPaymentStatusName(r.payment_status)}
                  </span>
                </div>
              </div>
            </div>
          )}

          {queryParams.qType === '常年會費' && (
            <div className="container">
              <div className="kickoff">
                <Tag color="cyan">{queryParams.qType}</Tag>
                {OrderCustomUtil.isCancelStatus(r.status) && (
                  <Tag color="orange">
                    {OrderCustomUtil.getStatusLabel(r.status, r.payment_status)}
                  </Tag>
                )}
                <span className="year">{r.annual_year}</span>
                <span className="created">
                  {new Date(r.created).toLocaleString('sv')}
                </span>
                <span className="number">
                  {r.order_id || `${r.order_number}*`}
                </span>
              </div>
              <div className="content">
                <span className="buyer">
                  {r.buyer_name} {r.buyer_phone} {r.buyer_email}
                </span>
                <div>
                  <span className="item">{r.item_name} </span>
                  {r.discount_name && (
                    <span className="discount">
                      [金額減項] {r.discount_name}
                    </span>
                  )}
                  <span className="amount">${r.amount}</span>
                  <span className={`${r.payment_status}`}>
                    {getPaymentStatusName(r.payment_status)}
                  </span>
                </div>
              </div>
            </div>
          )}

          {queryParams.qType === '跨區會費' && (
            <div className="container">
              <div className="kickoff">
                <Tag color="cyan">{queryParams.qType}</Tag>
                {OrderCustomUtil.isCancelStatus(r.status) && (
                  <Tag color="orange">
                    {OrderCustomUtil.getStatusLabel(r.status, r.payment_status)}
                  </Tag>
                )}
                <span className="year">{r.annual_year}</span>
                <span className="created">
                  {new Date(r.created).toLocaleString('sv')}
                </span>
                <span className="number">
                  {r.order_id || `${r.order_number}*`}
                </span>
              </div>
              <div className="content">
                <span className="buyer">
                  {r.buyer_name} {r.buyer_phone} {r.buyer_email}
                </span>
                <div>
                  <span className="item">{r.item_name} </span>
                  {r.discount_name && (
                    <span className="discount">
                      [金額減項] {r.discount_name}
                    </span>
                  )}
                  <span className="amount">${r.amount}</span>
                  <span className={`${r.payment_status}`}>
                    {getPaymentStatusName(r.payment_status)}
                  </span>
                </div>
              </div>
            </div>
          )}

          {(queryParams.qType === '課程' || queryParams.qType === '活動') && (
            <div className="container">
              <div className="kickoff">
                <Tag color="purple">{queryParams.qType}</Tag>
                {OrderCustomUtil.isCancelStatus(r.status) && (
                  <Tag color="orange">
                    {OrderCustomUtil.getStatusLabel(r.status, r.payment_status)}
                  </Tag>
                )}
                <span className="created">
                  {new Date(r.created).toLocaleString('sv')}
                </span>
                <span className="number">
                  {r.order_id || `${r.order_number}*`}
                </span>
              </div>
              <div className="content">
                <span className="buyer">
                  {r.buyer_name} {r.buyer_phone} {r.buyer_email}
                </span>
                <div>
                  <span className="item">{r.item_name} </span>
                  <span className="attend">{r.attend_type}</span>
                  {r.discount_name && (
                    <span className="discount">
                      [金額減項] {r.discount_name}
                    </span>
                  )}
                  <span className="amount">${r.amount}</span>
                  <span className={`${r.payment_status}`}>
                    {getPaymentStatusName(r.payment_status)}
                  </span>
                </div>
              </div>
            </div>
          )}

          {queryParams.qType === '藍新金流' && (
            <div className="container">
              <div className="kickoff">
                <Tag color="geekblue">{queryParams.qType}</Tag>
                {OrderCustomUtil.isCancelStatus(r.status) && (
                  <Tag color="orange">
                    {OrderCustomUtil.getStatusLabel(r.status, r.payment_status)}
                  </Tag>
                )}
                <span className="created">
                  {new Date(r.created).toLocaleString('sv')}
                </span>
                <span className="number">
                  {r.order_id || `${r.order_number}*`}
                </span>
              </div>
              <div className="kickoff">
                <div>商店訂單編號：{r.neweb_order_no || '---'}</div>
                <div>藍新金流交易序號：{r.neweb_trade_no || '---'}</div>
              </div>
              <div className="content">
                <span className="buyer">
                  {r.buyer_name} {r.buyer_phone} {r.buyer_email}
                </span>
                <div>
                  <span className="item">{r.item_name} </span>
                  <span className="amount">${r.amount}</span>
                  <span className={`${r.payment_status}`}>
                    {getPaymentStatusName(r.payment_status)}
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="action">
            <Button
              target="_blank"
              href={`${url}&backPath=/admin/orders/export`}>
              查看詳情
            </Button>
          </div>
        </ColumnWrapper>
      );
    },
  },
];

export default function AdminOrderExcelDownloadPage(props) {
  const {token: _token} = qs.parse(props.location.search);

  const [user] = useOutlet('user');
  const [products] = useOutlet('products');
  const [users] = useOutlet('users');

  const [isLoadingFetchData, setIsLoadingFetchData] = React.useState(true);
  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [fetchResp, setFetchResp] = React.useState(null);
  const [downloadUrl, setDownloadUrl] = React.useState(null);
  const [queryModalData, setQueryModalData] = React.useState({
    visible: false,
    data: {
      qType: '入會費',
      sorting: '-created',
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      paid_start_date: '',
      paid_end_date: '',
      is_receive: '---',
      payment_subtype: '---',
      status: '---',
    },
  });
  const queryParams = queryModalData.data;
  const token = _token || (user && !user.tmp && user.token);

  const fetchData = React.useCallback(async () => {
    try {
      setIsLoadingFetchData(true);
      AppActions.setLoading(true);
      setFetchResp(null);

      let resp;
      const query = await transformQuery(queryParams);

      if (
        queryParams.qType === '入會費' ||
        queryParams.qType === '常年會費' ||
        queryParams.qType === '跨區會費'
      ) {
        resp = await AppActionsEx.getOrderExportByFee({
          query,
          paging,
          sorting: queryParams.sorting,
        });
      }

      if (queryParams.qType === '課程' || queryParams.qType === '活動') {
        resp = await AppActionsEx.getOrderExportByCourse({
          query,
          paging,
          sorting: queryParams.sorting,
        });
      }

      if (queryParams.qType === '藍新金流') {
        resp = await AppActionsEx.getOrderExportByNeweb({
          query,
          paging,
          sorting: queryParams.sorting,
        });
      }

      setFetchResp(resp);
    } catch (ex) {
      console.warn(ex);
    } finally {
      AppActions.setLoading(false);
      setIsLoadingFetchData(false);
    }
    AppActions.setLoading(false);
  }, [queryParams, paging]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Wrapper>
      <h1 className="title">訂單查詢</h1>
      <div className="content">
        <section
          className="query"
          onClick={() => setQueryModalData({data: queryParams, visible: true})}>
          <h3>查詢參數</h3>

          {renderQueryInformation({
            ...queryParams,
            product_name: getProductName(products, queryParams.product),
          })}
        </section>

        <section className="actions">
          <Button
            target="_blank"
            href={`/print-preview?collection=order&type=export&queryParams=${JSON.stringify(
              {
                ...queryParams,
                product_name: getProductName(products, queryParams.product),
              },
            )}`}
            style={{marginRight: 10}}>
            預覽
          </Button>
          <Button
            onClick={async () =>
              exportExcelFile({queryParams, token, setDownloadUrl})
            }>
            匯出
          </Button>
          {downloadUrl && (
            <Button
              type="link"
              download={`${queryParams.qType}繳費明細.xlsx`}
              href={downloadUrl}
              target="_blank">
              下載
            </Button>
          )}
        </section>

        <Table
          columns={Columns(queryParams)}
          dataSource={fetchResp?.results || []}
          locale={{emptyText: isLoadingFetchData ? 'Loading' : undefined}}
          rowKey={'id'}
          pagination={{
            size: 'small',
            total: fetchResp?.total || 0,
            showSizeChanger: false,
            showTotal: (total) => `共 ${total} 筆, 每頁 ${pageSize} 筆`,
            current: paging.offset / pageSize + 1,
            pageSize: pageSize,
            position: ['topLeft'],
            onChange: (page, pageSize) => {
              if (pageSize) {
                setPaging({
                  offset: (page - 1) * pageSize,
                  limit: pageSize,
                });
              }
            },
          }}
        />
      </div>

      <QueryFormModal
        data={queryModalData}
        setData={setQueryModalData}
        setPaging={setPaging}
        fetchData={fetchData}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: #f8f9fa;
  min-height: 100vh;

  & > .title {
    font-size: 32px;
  }

  & > .content {
    width: min(720px, 100% - 20px);
    margin-inline: auto;
    background-color: white;
    border-radius: 12px;
    border: 1px solid #ddd;
    padding: 20px 10px;

    h1 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    section.query {
      border: 1px dashed var(--adminBorderColor);
      border-radius: 4px;
      padding: 12px;
      cursor: pointer;
      margin-bottom: 10px;

      :hover {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
    }

    section.actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
`;

const ColumnWrapper = styled.div`
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & > .container {
    flex: 1;

    & > .kickoff {
      margin-bottom: 10px;
      white-space: pre-wrap;

      .created,
      .year {
        padding-right: 5px;
      }

      .number {
        color: #888888;
      }
    }

    & > .content {
      margin-bottom: 5px;
      line-height: 20px;
      white-space: pre-wrap;
      font-weight: 400;

      .buyer,
      .amount,
      .item,
      .attend {
        white-space: pre-wrap;
        padding-right: 10px;
      }

      .discount {
        text-decoration: underline;
        white-space: pre-wrap;
        padding-right: 10px;
      }

      .pending {
        color: #0072e3;
      }

      .failure {
        color: #ce0000;
      }

      .success {
        color: #00bb00;
      }
    }
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: start;

    & > .action {
      display: flex;
      justify-content: end;
      width: 100%;
    }
  }
`;

function QueryFormModal(props) {
  const {
    data: {data, visible},
    setData,
    setPaging,
  } = props;
  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={600}
      visible={visible}
      onOk={() => {
        setData({
          data,
          visible: false,
        });
      }}
      onCancel={() => {
        setData({
          data,
          visible: false,
        });
      }}
      destroyOnClose={true}>
      {visible && <QueryModalContent {...props} />}
    </Modal>
  );
}

function QueryModalContent(props) {
  const {
    data: {data: prevData},
    setData,
    setPaging,
  } = props;

  const rjsfProps = {
    widgets: {
      'admin-select-product-widget': AdminSelectProductWidget,
      'admin-select-user-custom-widget': AdminSelectUserCustomWidget,
    },
  };

  return (
    <ModalWrapper>
      <h1>查詢參數</h1>

      <GenericForm
        {...FormSpec}
        rjsfProps={rjsfProps}
        instance={{...prevData}}
        onSubmit={async (formData, extValues) => {
          setData({visible: false, data: {...prevData, ...formData}});
          setPaging({offset: 0, limit: pageSize});
        }}
      />
    </ModalWrapper>
  );
}

const ModalWrapper = styled(ModalCustomStyle)`
  padding: 20px;
`;

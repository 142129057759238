import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Cart from 'rev.sdk.js/Actions/Cart';

export async function mergeCheckoutJStorageRespWithOrder(
  pagedCheckoutJStorageResp,
  pagedOrderProjection,
) {
  const orders = await JStorage.fetchDocuments(
    'order',
    {
      order_number: {
        $in: pagedCheckoutJStorageResp.results.map((r) => r.order_number),
      },
    },
    null,
    null,
    pagedOrderProjection,
  );

  const nextJStorageResult = {
    ...pagedCheckoutJStorageResp,
    results: pagedCheckoutJStorageResp.results.map((checkout) => {
      const order = orders.find(
        (order) => order.order_number === checkout.order_number,
      );

      return {
        ...checkout,
        ...order,
        id: checkout.id,
        __order_id: order?.id,
      };
    }),
  };

  return nextJStorageResult;
}

export function canCheckout(instance) {
  //TODO: remove offline payment subtype
  if (instance.status === 'time_canceled') {
    return false;
  }

  if (!instance.order_id && instance.expired > new Date().getTime()) {
    return true;
  }

  if (
    instance.order_id &&
    instance.payment_status === 'code_generated' &&
    new Date().getTime() > new Date(instance.pay_deadline).getTime() &&
    instance.expired > new Date().getTime()
  ) {
    return true;
  }

  if (
    instance.order_id &&
    instance.payment_status !== 'success' &&
    instance.payment_status !== 'code_generated' &&
    instance.expired > new Date().getTime()
  ) {
    return true;
  }

  return false;
}

export function getStatusLabel(status, payment_status) {
  if (status === 'return_applied') {
    return '取消報名審核通過';
  } else if (status === 'return_completed') {
    return '已退款';
  } else if (status === 'canceled') {
    return '已取消';
  } else if (payment_status === 'failure') {
    return '付款失敗';
  } else if (status === 'time_canceled') {
    return '已取消 (超過繳費時間)';
  } else if (status === 'stock_canceled') {
    return '已取消 (人數庫存額滿)';
  } else if (payment_status === 'success') {
    return '付款成功';
  } else {
    return Cart.PAYMENT_STATUS_DISPLAY[payment_status]?.label;
  }
}

export function isCancelStatus(status) {
  if (status === 'return_applied') {
    return true;
  } else if (status === 'return_completed') {
    return true;
  } else if (status === 'canceled') {
    return true;
  } else if (status === 'time_canceled') {
    return true;
  } else if (status === 'stock_canceled') {
    return true;
  }

  return false;
}
